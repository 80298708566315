import React, { useState } from "react";
import {
  StatusBar,
  StyleSheet,
  View,
  Text,
  Image,
  Button,
  Platform,
} from "react-native";
import { Amplify } from "aws-amplify";
import { WebView } from "react-native-webview";
import awsconfig from "./src/aws-exports";
//import Home from "./src/Home";
import HTML_FILE from "./resources/privacy_letters.html";

Amplify.configure(awsconfig);

const RenderWebViewElement = (props) => {
  let { index } = props;
  if (index === 1) {
    // Loading data from URL into webview
    return <iframe src={HTML_FILE} height={"100%"} width={"100%"} />;
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: "space-between",
    backgroundColor: "#E4F4FF",
    padding: 2,
    //margin: 10,
  },
  image: {
    flex: 1,
    //justifyContent: "center",
  },
  container2: {
    flex: 1,
    flexDirection: "row",
    padding: 1,
    margin: 1,
    alignContent: "center",
    justifyContent: "center",
    // justifyContent: "space-around",
    //borderWidth: 2,
    paddingLeft: 10,

    //height: 100,
  },

  top: {
    flex: 0.15,
    //backgroundColor: "#4182D8",
    backgroundColor: "#E4F4FF",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: "space-between",
    flexDirection: "row",
  },

  middle: {
    flex: 1,
    //backgroundColor: "#FFFBE7", // green
    backgroundColor: "#E4F4FF",
    flexDirection: "row",
    borderWidth: 2,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    //justifyContent: "space-around",
    //flexGrow: 1,
    //width: 500,
  },

  mleft: {
    flex: 0.5,
    flexDirection: "column",
    // alignContent: "space-between",
    //paddingTop: 5,
    justifyContent: "flex-start",
    // alignSelf: "flex-end",
    alignItems: "center",
    //width: 300,
    // paddingRight: 20,
    //paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 20,
    //borderWidth: 1,
    zIndex: 10,
    width: 500,
    //borderWidth: 1,
  },
  mright: {
    flex: 0.5,
    flexDirection: "column",
    // alignContent: "space-between",
    //paddingTop: 5,
    justifyContent: "flex-start",
    // alignSelf: "flex-end",
    alignItems: "center",
    //alignSelf: "center",
    //width: 300,
    // paddingRight: 20,
    //paddingBottom: 20,
    paddingTop: 20,
    paddingLeft: 20,
    //borderWidth: 1,
    zIndex: 10,
    width: 500,
    //borderWidth: 1,
  },

  heading: {
    fontFamily: "arial",
    alignSelf: "center",
    fontSize: 40,
    padding: 10,
  },
  otherText: {
    fontFamily: "arial",
    alignSelf: "center",
    fontSize: 20,
    padding: 10,
  },

  titleLogo: {
    alignSelf: "center",
    width: 800,
    height: 200,
  },
});

export default function App() {
  const [fragmentIndex, setFragmentIndex] = useState(1);
  return (
    <View style={styles.container}>
      <View>
        <View style={styles.container2}>
          <Image
            style={styles.titleLogo}
            source={require("./assets/yaddy6_E4F4FF.png")}
          />
        </View>
      </View>

      <View style={styles.middle}>
        <View style={styles.mleft}>
          <Text style={styles.heading}>About us</Text>
          <Text style={styles.otherText}>
            We are a Mobile app solutions company. We especialize in App
            Development and we have a few apps launching soon.
          </Text>
        </View>
        <View style={styles.mright}>
          <Text style={styles.heading}>Founder: Vanessa Jivan Bhimjee</Text>
          <Text style={styles.otherText}>
            Born in France, grew up in Madagascar and having managed retail
            businesses in Madagascar, Vanessa has a great accumen for business.
          </Text>
        </View>
      </View>
      <View>
        <div dangerouslySetInnerHTML={{ __html: HTML_FILE }} />
      </View>
    </View>
  );
}
